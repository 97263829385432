import React from "react"
import { graphql } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import * as actions from "../state/actions/keymans"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import OrganizationSeo from "../components/common/organization-seo"
import CorporationDetail from "../components/common/corporation-detail"
import Banner from "../components/common/banner"
import OtherKeymans from "../components/common/other-keymans"
import BreadcrumbList from "../components/common/breadcrumb-list"
import ServiceDescription from "../components/atoms/service-description"
import LinkToLpText from "../components/atoms/link-to-lp-text"

import { KEYMAN_PER_PAGE } from "../constants/constants"

export const query = graphql`
  query CorporationsQuery($slug: String) {
    corporationsJson(fields: { slug: { eq: $slug } }) {
      id
      corporate_number
      name
      prefecture_name
      city_name
      street_number
      corporation_en_name
      corporation_furigana
      change_cause
      listing_type
      capital_stock
      amount_of_sales
      number_of_employees
      logo_path
      corporation_detail_detail
      average_age
      annual_income
      clothing_month
      source_url
      patented
      registered_design
      registered_trademark
      shikiho_stock_code
      shikiho_site_url
      shikiho_feature
      shikiho_consolidated_business
      # shikiho_profile
      shikiho_tel
      shikiho_main_office
      # shikiho_topic1_content
      # shikiho_topic2_content
      financial_statements
      shikiho_performances
      has_intent_tag
      has_public_department_phone_number
    }
  }
`

const mapStateToProps = (state, ownProps) => {
  return {
    keymans: state.corporation.keymans.toIndexedSeq().toArray(),
    pageInfo_keyman: state.corporation.pageInfo_keyman,
    isLoadingKeymans: state.corporation.isLoadingKeymans,
    isLoadingCorp: state.corporation.isLoadingKeymans,
    isLoadingJsicSRank: state.industry.isLoadingJsicSRank,
    jsicSRankStats: state.industry.jsicSRankStats,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymans: (data, overwrite) =>
      dispatch(actions.getKeymans(data, overwrite)),
    getRankJsicSById: id => dispatch(actions.getRankJsicSById(id, null)),
  }
}

const styles = theme => ({
})

class Corporation extends React.Component {
  componentDidMount() {
    this.props.getKeymans(
      {
        corporation_id: [this.props.data.corporationsJson.id],
        per_page: KEYMAN_PER_PAGE,
        paginng: 1,
        page: 1,
      },
      true
    )
  }

  render() {
    const {
      data,
      isLoadingCorp,
      keymans,
      jsicSRankStats,
      getRankJsicSById,
    } = this.props

    return (
      <Layout>
        <SEO
          title={`${data.corporationsJson.name}の会社情報`}
          description={
            data.corporationsJson.shikiho_featuure
              ? data.corporationsJson.shikiho_featuure
              : `${data.corporationsJson.name}の会社情報` +
                " | 大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」なら48万人以上の決裁者をリスト化してレターでコンタクトが取れます。`"
          }
          canonical={`https://keyman-db.smart-letter.com/corporations/${this.props.data.corporationsJson.id}`}
        />
        <OrganizationSeo
          item={data.corporationsJson}
          companyName={data.corporationsJson.name}
          corporationId={Number(data.corporationsJson.id)}
        />
        <div className="container">
          <div className="list-container list-margin">
            <main className="left">
              <BreadcrumbList
                list={[
                  { path: "/", text: "トップ" },
                  { path: "/corporations", text: "会社一覧" },
                ]}
                current={{
                  path: `/corporations/${this.props.data.corporationsJson.id}`,
                  text: `${data.corporationsJson.name}の会社情報`,
                }}
                style={{ marginBottom: "12px" }}
              />
              <ServiceDescription
                children={<LinkToLpText/>}
              />
              <CorporationDetail
                item={data.corporationsJson}
                isLoading={isLoadingCorp}
                keymans={keymans}
                jsicSRankStats={jsicSRankStats}
                getRankJsicSById={getRankJsicSById}
              />
            </main>

            <div className="right">
              <Banner short={true} />
              <OtherKeymans
                keymans={keymans}
                corporationId={Number(data.corporationsJson.id)}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Corporation)
)
