import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import RankingProfile from "./ranking-profile"
import CorporationProfile from "./corporation-profile"

const styles = theme => ({
  root: {
    background: "#FFFFFF",
    borderRadius: "8px 8px 2px 2px",
    boxShadow: "0px 0px 3px #00000026",
    border: "1px solid #D8DBDF",
    position: "relative",
  },

  loaderContainer: {
    minHeight: "400px",
    position: "relative",
  },

  gutters: {
    width: "100%",
    background: "#040E35",
    color: "#FFFFFF",
    fontSize: "13px",
    fontWeight: "bold",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "8px 8px 0px 0px",
    padding: "10px 12px",
    boxSizing: "border-box",
    height: "37px",
  },

  section: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px #254E781A",
    position: "relative",
  },
})

const CorporationDetail = ({
  classes,
  item,
  keymans,
  jsicSRankStats,
  getRankJsicSById,
}) => {
  return (
    <div>
      <div className={classes.section}>
        <CorporationProfile
          item={item}
          keymans={keymans}
          jsicSRankStats={jsicSRankStats}
          getRankJsicSById={getRankJsicSById}
          corporationId={Number(item.id)}
          corporationName={item.name}
          source_url={item.source_url}
        />
      </div>

      <div className={classes.section} style={{ marginTop: "40px" }}>
        <RankingProfile
          item={item}
          jsicSRankStats={jsicSRankStats}
          getRankJsicSById={getRankJsicSById}
          text2={"この会社の決裁者に手紙を送ることができます"}
        />
      </div>

    </div>
  )
}

CorporationDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object,
}

export default withStyles(styles)(CorporationDetail)
